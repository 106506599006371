body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #fff;
}

a {
  outline: none;
}

#loginBox {
  height: 100%;
}

#root {
  height: 100%;
}

#startupFeedWrapper {
  margin-top: 1%;
}

#topBar {
  border-radius: 0px;
}

#overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  background-color: rgba(0, 0, 0, .2);
}

#canvasWrap {
  width: 100%;
  height: 100%;
  position: absolute;
}
